import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import { orderBy } from 'lodash';
import DataTable from '@/components/DataTable.vue';
import { LocationType, useGetAllCitiesQuery } from '@/generated-types/graphql.types';
import { CITY_DATA_TABLE_SETTINGS } from '@/Admin/const';
import { tableLabels } from '@/Admin/admin.const';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import { GeoPagesRoutes } from '@/GeoPages/geopages.const';
export default defineComponent({
    components: {
        DataTable
    },
    setup() {
        const dataTable = ref(null);
        const { result } = useGetAllCitiesQuery({
            entityType: LocationType.City,
            showAll: true,
            pagination: {
                page: 1,
                size: 1000
            }
        });
        const cities = computed(() => result?.value?.get_all_locations.result);
        watch(() => cities.value, () => {
            if (cities.value) {
                dataTable.value = cities.value?.map(city => ({
                    cityId: city?.seo_entity_id,
                    cityName: city?.seo_entity_name,
                    cityCounter: city?.counter,
                    cityUrl: city?.seo_entity_slug,
                    regionName: city?.seo_region_name
                }));
            }
        }, { immediate: true });
        const updateSort = ({ sortField, sort }) => {
            dataTable.value = orderBy(dataTable.value, [sortField], [sort]);
        };
        const goToCity = (slug) => {
            let route = globalThis.$router.resolve({
                path: useGetLocalizedPath(`${GeoPagesRoutes.CITIES}${slug}`)
            });
            window.open(route.href, '_blank');
        };
        return {
            cities,
            dataTable,
            tableLabels,
            tableSettings: computed(() => CITY_DATA_TABLE_SETTINGS),
            goToCity,
            updateSort
        };
    }
});
