import i18n from '@/i18n/i18n-setup';
import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import { orderBy } from 'lodash';
import DataTable from '@/components/DataTable.vue';
import { useAddFeedbackMutation, useEditFeedbackMutation, useDeleteFeedbackMutation, useGetAllFeedbacksQuery, UserRoleState } from '@/generated-types/graphql.types';
import { FEEDBACK_DATA_TABLE_SETTINGS } from '@/Admin/const';
import DeleteConfirmation from '@/shared/components/DeleteConfirmation.vue';
import AvatarEditor from '@/Account/components/AvatarEditor.vue';
import { validationMixin } from 'vuelidate';
import { maxLength, required } from 'vuelidate/lib/validators';
const tableLabels = [
    {
        name: 'feedbackOrder',
        label: i18n.t('admin.feedback.order').toString(),
        sortable: true,
        customElement: 'feedbackOrderSlot'
    },
    {
        name: 'userName',
        label: i18n.t('admin.feedback.user_name').toString(),
        sortable: true,
        customElement: 'userNameSlot'
    },
    {
        name: 'userAvatar',
        label: i18n.t('admin.feedback.user_avatar').toString(),
        sortable: false,
        customElement: 'userAvatarSlot'
    },
    {
        name: 'companyName',
        label: i18n.t('admin.feedback.company_name').toString(),
        sortable: true,
        customElement: 'companyNameSlot'
    },
    {
        name: 'jobTitle',
        label: i18n.t('admin.feedback.company_position').toString(),
        sortable: false,
        customElement: 'jobTitleSlot'
    },
    {
        name: 'userRole',
        label: i18n.t('admin.feedback.user_role').toString(),
        sortable: true,
        customElement: 'userRoleSlot'
    },
    {
        name: 'feedback',
        label: i18n.t('admin.feedback.feedback').toString(),
        sortable: false,
        customElement: 'feedbackSlot'
    },
    {
        name: 'action',
        label: i18n.t('admin.action').toString(),
        sortable: false,
        customElement: 'actionSlot'
    }
];
const feedbackFormDraft = {
    userName: '',
    companyName: '',
    jobTitle: '',
    userRole: UserRoleState.Booker,
    feedbackText: ''
};
export default defineComponent({
    components: {
        AvatarEditor,
        DataTable,
        DeleteConfirmation
    },
    mixins: [validationMixin],
    validations: {
        feedbackForm: {
            userName: {
                required,
                maxLength: maxLength(30)
            },
            companyName: {
                maxLength: maxLength(30)
            },
            jobTitle: {
                maxLength: maxLength(30)
            },
            userRole: {
                required
            },
            feedbackText: {
                required,
                maxLength: maxLength(500)
            }
        }
    },
    setup() {
        const dataTable = ref(null);
        const isDeleteConfirmationVisible = ref(false);
        const feedbackIdToDelete = ref(undefined);
        const avatarImage = ref(null);
        const isAvatarChanged = ref(false);
        const { mutate: addFeedbackMutation } = useAddFeedbackMutation({});
        const { mutate: editFeedbackMutation } = useEditFeedbackMutation({});
        const { result: allFeedbacks, refetch: refetchFeedbacks } = useGetAllFeedbacksQuery({});
        const userFeedbacks = computed(() => allFeedbacks?.value?.get_all_feedbacks);
        const { mutate: deleteFeedback } = useDeleteFeedbackMutation({});
        const selectData = ref([
            {
                label: UserRoleState.Booker,
                value: UserRoleState.Booker
            },
            {
                label: UserRoleState.Vendor,
                value: UserRoleState.Vendor
            }
        ]);
        /**
         * Watch the userFeedbacks array and refresh the table data
         */
        watch(() => userFeedbacks.value, () => {
            if (userFeedbacks.value) {
                dataTable.value = userFeedbacks.value?.map(feedback => ({
                    feedbackOrder: feedback?.feedback_id,
                    userName: feedback?.user_name,
                    avatarLink: feedback?.avatar_url,
                    companyName: feedback?.user_company_name,
                    jobTitle: feedback?.user_company_position,
                    userRole: feedback?.user_role.toString(),
                    text: feedback?.feedback
                }));
            }
        }, { immediate: true });
        /**
         * Remember the ID of the feedback and open the confirmation modal.
         * @param feedbackId
         */
        const onFeedbackDeleteRequest = (feedbackId) => {
            feedbackIdToDelete.value = feedbackId;
            isDeleteConfirmationVisible.value = true;
        };
        /**
         * Delete the feedback after user confirmation.
         */
        const onFeedbackDelete = () => {
            if (!feedbackIdToDelete.value)
                return;
            deleteFeedback({ feedbackId: feedbackIdToDelete.value }, {})
                .then(() => {
                feedbackIdToDelete.value = undefined;
                refetchFeedbacks();
            })
                .catch(({ graphQLErrors }) => {
                feedbackIdToDelete.value = undefined;
                if (graphQLErrors) {
                    console.log(graphQLErrors);
                }
            });
        };
        /**
         * Deep copy of the feedback form draft.
         */
        const feedbackForm = ref(JSON.parse(JSON.stringify(feedbackFormDraft)));
        const feedbackFormMode = ref('add');
        /**
         * @description
         * This function updates the sort order of the table
         * @param sortField
         * @param sort
         */
        const updateSort = ({ sortField, sort }) => {
            dataTable.value = orderBy(dataTable.value, [sortField], [sort]);
        };
        const feedbackModalOpened = ref(false);
        /**
         * Open the modal to add a new feedback.
         */
        const onAddNewFeedback = () => {
            feedbackFormMode.value = 'add';
            feedbackModalOpened.value = true;
        };
        /**
         * @description
         * This function triggers the GraphQL mutation to add a new feedback.
         * Available only for admin users.
         * After successful mutation, the feedbacks query is re-fetched.
         * @param form
         */
        const addNewFeedback = (form) => {
            if (form.$invalid) {
                return;
            }
            const { userName, companyName, jobTitle, userRole, feedbackText } = feedbackForm.value;
            addFeedbackMutation({
                avatarImage: avatarImage.value,
                userName: userName,
                userCompanyName: companyName,
                userCompanyPosition: jobTitle,
                userRole: userRole,
                feedback: feedbackText
            })
                .then(() => {
                feedbackModalOpened.value = false;
                avatarImage.value = null;
                isAvatarChanged.value = false;
                form.$reset();
                feedbackForm.value = JSON.parse(JSON.stringify(feedbackFormDraft)); // reset the form
                refetchFeedbacks();
            })
                .catch(({ graphQLErrors }) => {
                console.log(graphQLErrors);
            });
        };
        /**
         * @description
         * Detect the avatar change.
         * @param action string
         */
        const onAvatarChange = (action) => {
            if (action === 'removed' || action === 'updated') {
                isAvatarChanged.value = true;
            }
        };
        /**
         * @description
         * This function triggers the GraphQL mutation to edit the feedback.
         * Available only for admin users.
         * After successful mutation, the feedbacks query is re-fetched.
         * @param form
         */
        const editFeedback = (form) => {
            if (form.$invalid) {
                return;
            }
            const { feedbackId, userName, companyName, jobTitle, userRole, feedbackText } = feedbackForm.value;
            if (!feedbackId)
                return;
            editFeedbackMutation({
                feedbackId: feedbackId,
                avatarImage: avatarImage.value,
                userName: userName,
                userCompanyName: companyName,
                userCompanyPosition: jobTitle,
                userRole: userRole,
                feedback: feedbackText,
                isAvatarChanged: isAvatarChanged.value
            })
                .then(() => {
                feedbackModalOpened.value = false;
                avatarImage.value = null;
                isAvatarChanged.value = false;
                form.$reset();
                feedbackForm.value = JSON.parse(JSON.stringify(feedbackFormDraft)); // reset the form
                refetchFeedbacks();
            })
                .catch(({ graphQLErrors }) => {
                console.log(graphQLErrors);
            });
        };
        /**
         * Open edit feedback modal
         * @param feedback DataTable row data
         */
        const onFeedbackUpdate = (feedback) => {
            feedbackFormMode.value = 'edit';
            avatarImage.value = feedback.avatarLink;
            feedbackForm.value.feedbackId = feedback.feedbackOrder;
            feedbackForm.value.userName = feedback.userName;
            feedbackForm.value.companyName = feedback.companyName;
            feedbackForm.value.jobTitle = feedback.jobTitle;
            feedbackForm.value.userRole = feedback.userRole;
            feedbackForm.value.feedbackText = feedback.text;
            feedbackModalOpened.value = true;
        };
        const onModalClose = () => {
            avatarImage.value = null;
            isAvatarChanged.value = false;
            feedbackForm.value = JSON.parse(JSON.stringify(feedbackFormDraft)); // reset the form
        };
        return {
            avatarImage,
            dataTable,
            feedbackForm,
            feedbackFormMode,
            feedbackModalOpened,
            isDeleteConfirmationVisible,
            selectData,
            tableLabels,
            tableSettings: computed(() => FEEDBACK_DATA_TABLE_SETTINGS),
            userFeedbacks,
            UserRoleState,
            addNewFeedback,
            editFeedback,
            onAddNewFeedback,
            onAvatarChange,
            onFeedbackDelete,
            onFeedbackDeleteRequest,
            onFeedbackUpdate,
            onModalClose,
            updateSort
        };
    }
});
