import DataTable from '@/components/DataTable.vue';
import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import { useGetAllPayoutsQuery } from '@/generated-types/graphql.types';
import { PAYOUT_DATA_TABLE_SETTINGS } from '@/Admin/const';
import i18n from '@/i18n/i18n-setup';
import { orderBy } from 'lodash';
const tableLabels = [
    {
        name: 'bookingId',
        label: i18n.t('admin.payouts.booking_id').toString(),
        sortable: true,
        customElement: 'bookingIdSlot'
    },
    {
        name: 'venueName',
        label: i18n.t('admin.payouts.venue_name').toString(),
        sortable: true,
        customElement: 'venueNameSlot'
    },
    {
        name: 'spaceName',
        label: i18n.t('admin.payouts.space_name').toString(),
        sortable: true,
        customElement: 'spaceNameSlot'
    },
    {
        name: 'ibanName',
        label: i18n.t('admin.payouts.iban_name').toString(),
        sortable: true,
        customElement: 'ibanNameSlot'
    },
    {
        name: 'totalPaid',
        label: i18n.t('admin.payouts.total_paid').toString(),
        sortable: false,
        customElement: 'totalPaidSlot'
    },
    {
        name: 'payoutAmount',
        label: i18n.t('admin.payouts.payout_amount').toString(),
        sortable: false,
        customElement: 'payoutAmountSlot'
    },
    {
        name: 'ibanNumber',
        label: i18n.t('admin.payouts.iban_number').toString(),
        sortable: false,
        customElement: 'ibanNumberSlot'
    },
    {
        name: 'invoiceNumber',
        label: i18n.t('admin.payouts.invoice_number').toString(),
        sortable: false,
        customElement: 'invoiceNumberSlot'
    },
    {
        name: 'invoiceDate',
        label: i18n.t('admin.payouts.invoice_date').toString(),
        sortable: true,
        customElement: 'invoiceDateSlot'
    },
    {
        name: 'payoutStatus',
        label: i18n.t('admin.payouts.payout_status').toString(),
        sortable: true,
        customElement: 'payoutStatusSlot'
    },
    {
        name: 'payoutId',
        label: i18n.t('admin.payouts.payout_id').toString(),
        sortable: true,
        customElement: 'payoutIdSlot'
    },
    {
        name: 'payoutDate',
        label: i18n.t('admin.payouts.payout_date').toString(),
        sortable: true,
        customElement: 'payoutDateSlot'
    }
];
export default defineComponent({
    components: {
        DataTable
    },
    setup() {
        const dataTable = ref(null);
        const { result } = useGetAllPayoutsQuery();
        const bookingsPayout = computed(() => result?.value?.all_payouts);
        watch(() => bookingsPayout.value, () => {
            if (bookingsPayout.value) {
                dataTable.value = bookingsPayout.value?.map(payout => ({
                    bookingId: payout?.booking_id,
                    venueName: payout?.venue_name,
                    spaceName: payout?.space_name,
                    ibanName: payout?.iban_name,
                    totalPaid: payout?.amount_paid,
                    payoutAmount: payout?.payout_amount,
                    ibanNumber: payout?.iban_number,
                    invoiceNumber: payout?.invoice_number,
                    invoiceDate: payout?.invoice_date,
                    payoutStatus: payout?.payout_status,
                    payoutId: payout?.payout_id,
                    payoutDate: payout?.payout_date
                }));
            }
        }, { immediate: true });
        const updateSort = ({ sortField, sort }) => {
            dataTable.value = orderBy(dataTable.value, [sortField], [sort]);
        };
        return {
            bookingsPayout,
            dataTable,
            tableLabels,
            tableSettings: computed(() => PAYOUT_DATA_TABLE_SETTINGS),
            updateSort
        };
    }
});
