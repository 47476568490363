import { defineComponent, reactive, ref } from '@vue/composition-api';
import { useGetTopSpacesQuery, useEditTopSpacesMutation, useGetAllActiveSpacesQuery } from '@/generated-types/graphql.types';
import SpaceCard from '@/Search/components/SpaceCard.vue';
import draggable from 'vuedraggable';
import SaveFooter from '@/pages/profile/components/SaveFooter.vue';
export default defineComponent({
    name: 'HighlightedDashboard',
    components: {
        draggable,
        SaveFooter,
        SpaceCard
    },
    setup() {
        const cardModalOpened = ref(false);
        const { onResult: onTopSpacesResult } = useGetTopSpacesQuery({});
        const allActiveSpacesQueryOptions = reactive({
            enabled: cardModalOpened.value,
            fetchPolicy: 'cache-and-network'
        });
        const { onResult: onActiveSpacesResult, refetch: refetchAllActiveSpaces } = useGetAllActiveSpacesQuery(allActiveSpacesQueryOptions);
        const { mutate: editTopSpaces } = useEditTopSpacesMutation({});
        const topSpaces = ref([]);
        const availableSpaces = ref([]);
        const isSpaceCardsArrayChanged = ref(false);
        const isSpaceCardChangesSaved = ref(false);
        const initialSpacesArray = ref([]);
        const selectedSpaceId = ref(null);
        /**
         * @description
         * Watcher of the useGetAllActiveSpacesQuery.
         * If the results are still loading, do nothing.
         * When the results are loaded from the server, the modal is opened.
         * Waiting for the server results is necessary to properly set the slots of the Select.vue in the modal.
         */
        onActiveSpacesResult(result => {
            if (result.loading)
                return;
            const topsSpacesIds = topSpaces.value.map(space => space.spaceId);
            availableSpaces.value = result.data.get_all_active_spaces
                .map(space => {
                return {
                    images: space.space_images.map(image => image && image.image_url),
                    bookingType: space.space_booking_type,
                    label: `${space.venue.venue_name} - ${space.internal_name}`,
                    venueName: space.venue.venue_name,
                    activities: space.space_categories.map(category => category && category.category_id),
                    spaceName: space.space_name,
                    spaceUrl: space.space_url,
                    value: space.space_id,
                    internalName: space.internal_name,
                    halfDayPrice: space.half_day_price,
                    spaceSize: space.space_size,
                    isAfterpayAllowed: space.is_afterpay_allowed,
                    attendees: {
                        minGuests: space.min_guests,
                        maxGuests: space.max_guests
                    },
                    cityName: space.venue.venue_geo.city.seo_entity_name,
                    cityUrl: space.venue.venue_geo.city.seo_entity_slug
                };
            })
                .filter(space => !topsSpacesIds.includes(space.value));
            cardModalOpened.value = true;
        });
        onTopSpacesResult(result => {
            topSpaces.value = result.data.get_top_spaces.map(space => {
                return {
                    images: space.space_images.map(image => image && image.image_url),
                    bookingType: space.space_booking_type,
                    venueName: space.venue.venue_name,
                    features: space.space_features.map(el => el.feature_id),
                    activities: space.space_categories.map(category => category && category.category_id),
                    spaceName: space.space_name,
                    spaceUrl: space.space_url,
                    spaceId: space.space_id,
                    internalName: space.internal_name,
                    halfDayPrice: space.half_day_price,
                    spaceSize: space.space_size,
                    isAfterpayAllowed: space.is_afterpay_allowed,
                    attendees: {
                        minGuests: space.min_guests,
                        maxGuests: space.max_guests
                    },
                    cityName: space.venue.venue_geo.city.seo_entity_name,
                    cityUrl: space.venue.venue_geo.city.seo_entity_slug,
                    isHighlighted: space.is_highlighted
                };
            });
            initialSpacesArray.value = [...topSpaces.value]; // copy array
        });
        /**
         * Remove the space card from the topSpaces array and trigger the footer buttons
         * @param spaceId number
         */
        const onSpaceCardRemove = (spaceId) => {
            topSpaces.value = topSpaces.value?.filter(space => space.spaceId !== spaceId);
            isSpaceCardsArrayChanged.value =
                initialSpacesArray.value !== topSpaces.value;
        };
        /**
         * Check if there are changes in space cards order
         */
        const hasChanges = () => {
            return isSpaceCardsArrayChanged.value;
        };
        /**
         * Save new space cards order and refetch the available spaces list
         */
        const onSaveChanges = () => {
            const mutationInput = topSpaces.value?.map((space, i) => {
                return {
                    space_id: space.spaceId,
                    rank: i
                };
            });
            editTopSpaces({
                input: mutationInput
            })
                .then(() => {
                initialSpacesArray.value = [...topSpaces.value]; // copy array
                isSpaceCardsArrayChanged.value = false;
                isSpaceCardChangesSaved.value = true;
            })
                .catch(({ graphQLErrors }) => {
                console.log(graphQLErrors);
            });
        };
        /**
         * Revert space cards order to the initial state
         */
        const onRevertChanges = () => {
            topSpaces.value = [...initialSpacesArray.value]; // copy array
            isSpaceCardsArrayChanged.value = false;
        };
        /**
         * Define if topSpaces array was changed after the drag action end
         */
        const onDragEnd = () => {
            isSpaceCardsArrayChanged.value =
                initialSpacesArray.value !== topSpaces.value;
        };
        /**
         * Show footer success message after successful mutation call
         */
        const isSaved = () => {
            return isSpaceCardChangesSaved.value;
        };
        /**
         * Hide footer success message after closing the footer
         */
        const onFooterClose = () => {
            isSpaceCardChangesSaved.value = false;
        };
        /**
         * When the new space is added in the modal, close the modal and trigger the footer buttons
         */
        const onNewTopAdded = () => {
            const selectedSpace = availableSpaces.value.find(space => space.value === selectedSpaceId.value);
            if (!selectedSpace)
                return;
            topSpaces.value?.push({ ...selectedSpace, spaceId: selectedSpace.value });
            isSpaceCardChangesSaved.value = false;
            cardModalOpened.value = false;
            isSpaceCardsArrayChanged.value = true;
        };
        /**
         * Drop the selected space id when the modal is closed
         */
        const onModalClose = () => {
            selectedSpaceId.value = null;
        };
        /**
         * @description
         * On the click of the add top space icon, the available active spaces are requested from the server
         * (see onActiveSpacesResult).
         */
        const onCardModalOpened = () => {
            allActiveSpacesQueryOptions.enabled = true;
            refetchAllActiveSpaces();
        };
        return {
            availableSpaces,
            cardModalOpened,
            selectedSpaceId,
            topSpaces,
            hasChanges,
            isSaved,
            onCardModalOpened,
            onDragEnd,
            onFooterClose,
            onModalClose,
            onNewTopAdded,
            onRevertChanges,
            onSaveChanges,
            onSpaceCardRemove
        };
    }
});
