import { defineComponent } from '@vue/composition-api';
import { useGetCustomerTokensQuery } from '@/generated-types/graphql.types';
export default defineComponent({
    props: {
        id: {
            type: Number,
            default: null
        }
    },
    setup(props, { emit }) {
        const { onResult } = useGetCustomerTokensQuery(() => ({
            accountId: props.id
        }));
        onResult(result => {
            const customerTokens = result.data?.get_customer_tokens;
            // WM-2769; bug causing saved storage data to confuse our admin users in thinking the vendor data is incorrect
            localStorage.clear();
            globalThis.$store?.dispatch('authentication/authorize', {
                accessToken: customerTokens.access_token,
                refreshToken: customerTokens.refresh_token
            });
            emit('authorized');
        });
        return {};
    }
});
