import { defineComponent } from '@vue/composition-api';
import { useGetVendorTokensQuery } from '@/generated-types/graphql.types';
export default defineComponent({
    props: {
        id: {
            type: Number,
            default: null
        }
    },
    setup(props, { emit }) {
        const { onResult } = useGetVendorTokensQuery(() => ({
            accountId: props.id
        }));
        onResult(result => {
            const vendorTokens = result.data?.get_vendor_tokens;
            // WM-2769; bug causing saved storage data to confuse our admin users in thinking the vendor data is incorrect
            localStorage.clear();
            globalThis.$store?.dispatch('authentication/authorize', {
                accessToken: vendorTokens.access_token,
                refreshToken: vendorTokens.refresh_token
            });
            emit('authorized');
        });
        return {};
    }
});
